import React, { Fragment, useEffect, useState } from "react";

import PensionBarChart from "../components/results/charts/PensionBarChart";
import LumpSumBarChart from "../components/results/charts/LumpSumBarChart";
import SummaryHeader from "../components/results/SummaryHeader";
import RetirementAgeSwtich from "../components/results/switches/RetirementAgeSwitch";
import InflationSwitch from "../components/results/switches/InflationSwitch";
import LumpSumSwitch from "../components/results/switches/LumpSumSwitch";
import ComparisonTable from "../components/results/ComparisonTable";
import NuvosWarning from "../components/results/NuvosWarning";
import SalarySlider from "../components/results/switches/SalarySlider";

import ResultsVideo from "../assets/videos/results_cropped.mp4";
import MainTextModal from "../components/general/VideoModalMainText";

import { useScrollPosition } from "../utilities/UseScrollPosition";

import classes from "./3-Results.module.css";

function ResultsPage(props) {
  const [adjustInflation, setAdjustInflation] = useState("real");
  const [equaliseLumpSum, setEqualiseLumpSum] = useState("unequal");
  const [retirementAge, setRetirementAge] = useState("normal");
  const [salaryIncrease, setSalaryIncrease] = useState(2);
  const [mouseDown, setMouseDown] = useState(false);
  const [force_chart_repaint, set_force_chart_repaint] = useState(false)
  const [showModal, setShowModal] = useState(true)
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const scrollPosition = useScrollPosition();



  useEffect(() => {
    if (!mouseDown) {

       const data_sent_to_backend = {...props.all_data, salary_increase : salaryIncrease}

      {
        var address =
          (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) +
          "/api/setup";
        var requestOptions = {
          method: "Post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data_sent_to_backend),
        };
  
        fetch(address, requestOptions)
          .then((response) => response.json())
          .then(function (data) {
            //console.log(data); 
            //console.log(salaryIncrease); 
            props.set_user_data(data);
            set_force_chart_repaint(!force_chart_repaint)
            //console.log('updated user data')
            //console.log('data: ', data['results2']['nominal']['data_pre_remedy'])
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }, [mouseDown]);



  //console.log(props.user_data)
  //console.log('user data: ', props.user_data['results2']['nominal']['data_pre_remedy'])
  let USER_DATA = props.user_data["results2"][adjustInflation];
  let userRetirementAge = props.user_data["retirement_age"];
  let userExactAge = props.user_data["exact_age"];
  let remedySchemeName = "alpha";
  let legacySchemeName = USER_DATA["labels"]["scheme"][1].replace(
    /\b\w/g,
    (c) => c.toUpperCase()
  );
  let scenariosCount = props.user_data["n_scenarios"] * 2;

  let showLumpSums = true;
  if (legacySchemeName === "Premium" || legacySchemeName === "Nuvos") {
    showLumpSums = false;
  }

  let showEqualiseSwitch = true;
  if (legacySchemeName === "Classic Plus") {
    showEqualiseSwitch = false;
  }

  let showPreRemedy = true;
  let showNuvosWarning = false;
  if (legacySchemeName === "Nuvos") {
    showPreRemedy = false;
    showNuvosWarning = true;
  }

  let lumpSumArraySting;
  if (equaliseLumpSum === "equal") {
    lumpSumArraySting = "";
  } else {
    lumpSumArraySting = "_unequal";
  }

  let scenarioName;

  let showInflationSwitch = true;
  switch (true) {
    case scenariosCount === 6:
      scenarioName = "early_desired_late";
      break;
    case scenariosCount === 4 &&
      Math.min(...USER_DATA["labels"]["ages"]) === userRetirementAge:
      scenarioName = "desired_late";
      break;
    case scenariosCount === 4 &&
      Math.max(...USER_DATA["labels"]["ages"]) === userRetirementAge:
      scenarioName = "early_desired";
      break;
    case scenariosCount === 2:
      scenarioName = "desired";
      break;
  }

  /* really, it should not be possible for the retirement age to be below the exact age; in theory we should be able to
use `==` in the condition below instead of `<=`. However, to account for the possibility of the exact age being reduced
by a tiny amount (e.g. due to the way python treats floats), I have used <= instead. */
  if (userRetirementAge <= userExactAge) {
    showInflationSwitch = false;
  }

  let compiledPensionsData = {
    earlyRetirement: {
      retirementAge: parseInt(USER_DATA["labels"]["ages"][scenariosCount - 6]),
      reformedPension: {
        preRemedy: showPreRemedy
          ? USER_DATA["data_pre_remedy"][scenariosCount - 6]
          : 0,
        remedy:
          USER_DATA["data_remedy" + lumpSumArraySting][scenariosCount - 6],
        post2022:
          USER_DATA["data_2022" + lumpSumArraySting][scenariosCount - 6],
      },
      legacyPension: {
        preRemedy: showPreRemedy
          ? USER_DATA["data_pre_remedy"][scenariosCount - 5]
          : 0,
        remedy:
          USER_DATA["data_remedy" + lumpSumArraySting][scenariosCount - 5],
        post2022:
          USER_DATA["data_2022" + lumpSumArraySting][scenariosCount - 5],
      },
      lumpSums: {
        reformed: USER_DATA["lump sum" + lumpSumArraySting][scenariosCount - 6],
        legacy: USER_DATA["lump sum" + lumpSumArraySting][scenariosCount - 5],
      },
    },
    normalRetirement: {
      retirementAge: parseInt(USER_DATA["labels"]["ages"][scenariosCount - 4]),
      reformedPension: {
        preRemedy: showPreRemedy
          ? USER_DATA["data_pre_remedy"][scenariosCount - 4]
          : 0,
        remedy:
          USER_DATA["data_remedy" + lumpSumArraySting][scenariosCount - 4],
        post2022:
          USER_DATA["data_2022" + lumpSumArraySting][scenariosCount - 4],
      },
      legacyPension: {
        preRemedy: showPreRemedy
          ? USER_DATA["data_pre_remedy"][scenariosCount - 3]
          : 0,
        remedy:
          USER_DATA["data_remedy" + lumpSumArraySting][scenariosCount - 3],
        post2022:
          USER_DATA["data_2022" + lumpSumArraySting][scenariosCount - 3],
      },
      lumpSums: {
        reformed: USER_DATA["lump sum" + lumpSumArraySting][scenariosCount - 4],
        legacy: USER_DATA["lump sum" + lumpSumArraySting][scenariosCount - 3],
      },
    },
    lateRetirement: {
      retirementAge: parseInt(USER_DATA["labels"]["ages"][scenariosCount - 2]),
      reformedPension: {
        preRemedy: showPreRemedy
          ? USER_DATA["data_pre_remedy"][scenariosCount - 2]
          : 0,
        remedy:
          USER_DATA["data_remedy" + lumpSumArraySting][scenariosCount - 2],
        post2022:
          USER_DATA["data_2022" + lumpSumArraySting][scenariosCount - 2],
      },
      legacyPension: {
        preRemedy: showPreRemedy
          ? USER_DATA["data_pre_remedy"][scenariosCount - 1]
          : 0,
        remedy:
          USER_DATA["data_remedy" + lumpSumArraySting][scenariosCount - 1],
        post2022:
          USER_DATA["data_2022" + lumpSumArraySting][scenariosCount - 1],
      },
      lumpSums: {
        reformed: USER_DATA["lump sum" + lumpSumArraySting][scenariosCount - 2],
        legacy: USER_DATA["lump sum" + lumpSumArraySting][scenariosCount - 1],
      },
    },
  };

  let userPensionData;
  switch (scenarioName) {
    case "early_desired":
      userPensionData = (({ normalRetirement, lateRetirement }) => ({
        normalRetirement,
        lateRetirement,
      }))(compiledPensionsData);
      break;
    case "desired_late":
      userPensionData = (({ normalRetirement, lateRetirement }) => ({
        normalRetirement,
        lateRetirement,
      }))(compiledPensionsData);
      break;
    case "early_desired_late":
      userPensionData = (({
        earlyRetirement,
        normalRetirement,
        lateRetirement,
      }) => ({
        earlyRetirement,
        normalRetirement,
        lateRetirement,
      }))(compiledPensionsData);
      break;
    case "desired":
      userPensionData = (({ lateRetirement }) => ({
        lateRetirement,
      }))(compiledPensionsData);
      break;
  }

  let resultsPensionData;
  if (scenarioName === "desired") {
    resultsPensionData = userPensionData["lateRetirement"];
  } else {
    resultsPensionData = userPensionData[retirementAge + "Retirement"];
  }

  const [showEqualiseWarning, setShowEqualiseWarning] = useState(false);

  useEffect(() => {
    if (
      (resultsPensionData.lumpSums.reformed !==
        resultsPensionData.lumpSums.legacy) &
      (equaliseLumpSum === "equal")
    ) {
      setShowEqualiseWarning(true);
    } else {
      setShowEqualiseWarning(false);
    }
    console.log(showEqualiseWarning);
  }, [resultsPensionData.retirementAge, equaliseLumpSum]);

  useEffect(() => {
    if (scrollPosition > 90) {
      setIsScrolledDown(true);
    } else {
      setIsScrolledDown(false);
    }
  }, [scrollPosition]);

  //console.log('resultspensiondata: ', resultsPensionData)

  return (
    <div id="Results_Page">
      {showModal && <div style={{ height: "9.75rem" }}></div>}
      <MainTextModal
        videoTitle={"Results Page Guide"}
        video={ResultsVideo}
        at_top={!isScrolledDown}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <h2>Your Illustrated Results</h2>
      <p>
        The following page provides a simple illustration of the annual pension
        and lump sum that you may receive at different retirement ages depending
        on whether you choose legacy or alpha benefits for the remedy period.
        The figures below are in {adjustInflation} terms.
      </p>
      <p>
        Please note that in some cases, your total pension might not precisely
        match the sum of the individual amounts due to rounding.
      </p>
      {showNuvosWarning && <NuvosWarning />}
      <SummaryHeader
        showLumpSums={showLumpSums}
        legacySchemeName={legacySchemeName}
        remedySchemeName={remedySchemeName}
        resultsPensionData={resultsPensionData}
        adjustInflation={adjustInflation}
      />
      <RetirementAgeSwtich
        scenarioName={scenarioName}
        setRetirementAge={setRetirementAge}
      />
      {showLumpSums && (
        <Fragment>
          <div className={classes.chart_container}>
            <div className={classes.chart}>
              <PensionBarChart
                key={1}
                showPreRemedy={showPreRemedy}
                retirementAge={resultsPensionData.retirementAge}
                reformedPreRemedy={resultsPensionData.reformedPension.preRemedy}
                reformedRemedy={resultsPensionData.reformedPension.remedy}
                reformedPost2022={resultsPensionData.reformedPension.post2022}
                legacyPreRemedy={resultsPensionData.legacyPension.preRemedy}
                legacyRemedy={resultsPensionData.legacyPension.remedy}
                legacyPost2022={resultsPensionData.legacyPension.post2022}
                scheme={legacySchemeName}
                adjustInflationState={adjustInflation}
                equaliseLumpSumState={equaliseLumpSum}
                retirementAgeState={retirementAge}
                force_chart_repaint = {force_chart_repaint}
              />
            </div>
            <div className={classes.chart}>
              <LumpSumBarChart
                key={2}
                retirementAge={resultsPensionData.retirementAge}
                reformedLumpSum={resultsPensionData.lumpSums.reformed}
                legacyLumpSum={resultsPensionData.lumpSums.legacy}
                scheme={legacySchemeName}
                adjustInflationState={adjustInflation}
                equaliseLumpSumState={equaliseLumpSum}
                retirementAgeState={retirementAge}
                force_chart_repaint = {force_chart_repaint}
              />
            </div>
          </div>
          <div className={classes.switch_container}>
            {showInflationSwitch && (
              <InflationSwitch setAdjustInflation={setAdjustInflation} />
            )}
            {showEqualiseSwitch && (
              <LumpSumSwitch
                setEqualiseLumpSum={setEqualiseLumpSum}
                showEqualiseWarning={showEqualiseWarning}
              />
            )}
            <SalarySlider
              salaryIncrease={salaryIncrease}
              setSalaryIncrease={setSalaryIncrease}
              setMouseDown={setMouseDown}
              legacySchemeName={legacySchemeName}
            />
          </div>
        </Fragment>
      )}
      {!showLumpSums && (
        <Fragment>
          <div className={classes.joint_container}>
            <div className={classes.chart2}>
              <PensionBarChart
                key={1}
                showPreRemedy={showPreRemedy}
                retirementAge={resultsPensionData.retirementAge}
                reformedPreRemedy={resultsPensionData.reformedPension.preRemedy}
                reformedRemedy={resultsPensionData.reformedPension.remedy}
                reformedPost2022={resultsPensionData.reformedPension.post2022}
                legacyPreRemedy={resultsPensionData.legacyPension.preRemedy}
                legacyRemedy={resultsPensionData.legacyPension.remedy}
                legacyPost2022={resultsPensionData.legacyPension.post2022}
                scheme={legacySchemeName}
                adjustInflationState={adjustInflation}
                equaliseLumpSumState={equaliseLumpSum}
                retirementAgeState={retirementAge}
                force_chart_repaint = {force_chart_repaint}
              />
            </div>
            <div className = {classes.switch_container2}> 
              {showInflationSwitch && (
                <InflationSwitch setAdjustInflation={setAdjustInflation} />
              )}
              <SalarySlider
                salaryIncrease={salaryIncrease}
                setSalaryIncrease={setSalaryIncrease}
                setMouseDown={setMouseDown}
                legacySchemeName={legacySchemeName}
              />
            </div>
          </div>
        </Fragment>
      )}
      <ComparisonTable
        showLumpSums={showLumpSums}
        showPreRemedy={showPreRemedy}
        scenariosCount={scenariosCount}
        legacySchemeName={legacySchemeName}
        remedySchemeName={remedySchemeName}
        compiledPensionsData={compiledPensionsData}
        adjustInflation={adjustInflation}
      />
      {/* <p>
        <pre>{JSON.stringify(props.user_data, null, 2)}</pre>
      </p> */}
    </div>
  );
}

export default ResultsPage;
