import React from "react";

import classes from "./SurveyModal.module.css";

const SurveyModal = (props) => {
  const openSurvey = () => {
    var href =
      "https://www.smartsurvey.co.uk/s/gad-remedy-benefits-illustrator";
    window.open(href, "_blank");
    props.onClick();
  };

  return (
    <div className={classes.modal}>
      <h3>Give Feedback</h3>
      <p>
        We would love to know what you think of our tool! Please consider giving
        us your feedback using the link below:
      </p>
      <button className={classes.survey_btn} onClick={openSurvey}>
        Open survey
      </button>
      <button className={classes.close_btn} onClick={props.onClick}>
        No thanks!
      </button>
    </div>
  );
};

export default SurveyModal;
