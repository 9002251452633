import React, { useState, useEffect, useRef } from "react";
import PageButton from "../general/buttons/PageButton";
import Question from "./Question";
import { MemberQuestions } from "../../assets/data/QuestionsText";
import classes from "./InputForm.module.css";

//import moment from "moment";

const InputForm = (props) => {
  const [still_typing, set_still_typing] = useState(false)
  const timeoutRef = useRef(false)
  const InputGetter = (input, question_id, question_type) => {

    set_still_typing(true)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

  // Set a new timeout to
    timeoutRef.current = setTimeout(() => {
        set_still_typing(false)
        console.log('not typing')
    }, 500);

    if ((question_type === "number") & (input.target.value !== "")) {
      var new_value = Math.floor(input.target.value);
    } else {
      var new_value = input.target.value;
    }
    props.setdict({ ...props.dict, [question_id]: new_value });



  };

  const DateInputGetter = (input, question_id) => {
    
    set_still_typing(true)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

  // Set a new timeout 
    timeoutRef.current = setTimeout(() => {
        set_still_typing(false)
        console.log('not typing')
    }, 500);

    props.setdict({ ...props.dict, [question_id]: input });



  };

  useEffect(() => {
    //if (dict['if_pt'] === 'No' ) {setskips(1)} else if (dict['if_pt'] === undefined || dict['if_pt'] === 'Yes') {setskips(0)}
    //if (dict['if_left'] === 'No') {setskips(skips+1)}
    const arr = [
      props.dict["if_pt"] === "No",
      (props.dict["if_left"] === "No") &
        !Object.keys(props.dict).includes("dateLeftScheme"),
    ];

    const count = arr.filter(Boolean).length;

    props.setskips(count);
  }, [props.dict, props.skips]);

  useEffect(
    () =>
      props.setcurrentIndex(Object.keys(props.dict).length + 1 + props.skips),
    [props.dict, props.skips]
  );

  /* get SPA if DOB is available */
  useEffect(() => {
    if (Object.keys(props.dict).includes("dateOfBirth")) {
      var address =
        (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) +
        "/api/spa";
      var requestOptions = {
        method: "Post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ dob: props.dict["dateOfBirth"] }),
      };

      fetch(address, requestOptions)
        .then((response) => response.json())
        .then(function (data) {
          props.set_SPA(data["SPA"]);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [props.dict["dateOfBirth"]]);

  const maxIndex = MemberQuestions.length - 1; //-1 because there is a dummy question

  const q_text =  {
    "receipt": "blank",
    "if_left": "blank",
    "dateLeftScheme": "blank",
    "dateOfBirth": "This calculator can only be used by those who have not yet reached their State Pension Age; entering a date of birth corresponding to a State Pension date that has been reached in the past is not possible",
    "scheme": "blank",
    "dateJoinedScheme": "If you don't know the exact date when you joined the scheme, please provide the approximate date. If you have multiple periods of service because you left and then rejoined, please provide the earliest joining date, and in the question about part time working that will appear below on this page, enter any intervening periods during which you were not in service as part time periods with the part time proportion being zero. Classic members should have a joining date on or before 30 September 2002; Premium members should have a joining date between 01 October 2002 and 30 July 2007",
    "salary1": "This should be pensionable earnings and should be the Full-Time Equivalent amount. For example, if you work three days per week and earn £15,000 pa for those three days, then your FTE would be what you would earn if you worked five days, in this case £25,000. Please note that final pensionable pay used in this illustrator is based on gross pay at the point of retirement which differs from the actual final pensionable pay definitions that will be used at retirement.",
    "salary2": "This should be pensionable earnings and should be the Full-Time Equivalent amount. For example, if you work three days per week and earn £15,000 pa for those three days, then your FTE would be what you would earn if you worked five days, in this case £25,000. Please note that final pensionable pay used in this illustrator is based on gross pay at the point of retirement which differs from the actual final pensionable pay definitions that will be used at retirement.",
    "if_pt": "blank",
    "how_pt": "blank",
    "salary_nuvos": "blank",
    "accrued_nuvos": "blank",
    "retirement_age": "This must be a whole number not greater than 70 and not earlier than the higher of 55 and your age at your next birthday (or your current age if today is your birthday)"
  };


  var validations = [];
  var which_failed = [];

  const questions = MemberQuestions.map((question) => {
    var current_validation =
      question.validation === undefined
        ? true
        : question.validation(
            props.dict,
            question.maxval(props.all_data),
            question.minval(props.all_data)
          );

    validations = [...validations, current_validation];
    var passed_validations = validations.filter(Boolean).length;
    if (!current_validation) {
      which_failed = [...which_failed, question.id];
    }
    if (question.index > 8 && (which_failed.length > 0) | props.forbidnext) {
      props.set_inputs_valid(false);
    } else {
      props.set_inputs_valid(true);
    }


    return (
      <Question
        key={question.id}
        id={question.id}
        //ref={question.id}
        thisIndex={question.index}
        already_receipt={
          (question.id !== "receipt") & (props.dict["receipt"] === "Yes")
        }
        not_pt={(question.id === "how_pt") & (props.dict["if_pt"] === "No")}
        not_left={
          (question.id === "dateLeftScheme") & (props.dict["if_left"] === "No")
        }
        //member_nuvos = {question.index > 4 & props.dict["scheme"] === "nuvos"}
        validation_issue={
          ((passed_validations < question.index) & current_validation) |
          (passed_validations + 1 < question.index)
        }
        currentIndex={props.currentIndex}
        maxIndex={maxIndex}
        title={question.title}
        text={question.text}
        type={question.type}
        SideText={q_text[question.id]}
        options={question.options}
        //clickHandler={() => handler()}
        InputGetter={(input) => InputGetter(input, question.id, question.type)}
        DateInputGetter={(input) => DateInputGetter(input, question.id)}
        dict={props.dict}
        set_all_data={props.set_all_data}
        setInpts2={props.setInpts2}
        ptinputs2={props.ptinputs2}
        maxval={
          question.maxval === undefined ? "" : question.maxval(props.all_data)
        }
        minval={
          question.minval === undefined ? "" : question.minval(props.all_data)
        }
        validations={validations}
        setforbidnext={props.setforbidnext}
        forbidnext={props.forbidnext}
        page={props.page}
        setpage={props.setpage}
        showwarning={props.showwarning}
        setshowwarning={props.setshowwarning}
        selected={
          (question.id === "dateJoinedScheme") &
          (props.dict["scheme"] === "Premium")
            ? new Date("2004-01-01")
            : question.id === "dateJoinedScheme"
            ? new Date("1995-01-01")
            : question.selected 
        }
        still_typing = {still_typing}
      />
    );
  });

  const handleSubmit = async () => {
    //setdotest(true)

    const requestOptions = {
      method: "Post",
      headers: { "Content-Type": "application/json" },
      //functions in the backend don't work without values provided for irrelevant fields, so
      //enter arbitrary values for these.
      body: JSON.stringify({
        ...props.all_data,
        ["salaries"]: [props.all_data["salary1"], props.all_data["salary2"]],
      }),
    };

    let address = props.currentURL + "/api/setup"; //xxx
    fetch(address, requestOptions)
      .then((response) => response.json())
      .then(function (data) {
        props.set_user_data(data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }; //end of handleSubmit

  useEffect(() => {
    if (
      props.all_data.hasOwnProperty("if_pt") &&
      !which_failed.includes("retirement_age")
    ) {
      handleSubmit();
    }
  }, [props.all_data]);

  return (
    <form className={classes.formbox}>
      <div>{questions}</div>
      {props.dict["receipt"] === "Yes" && (
        <div className={classes.textbox}>
          <p>
            As you have already retired or partially retired, your pension
            administrator will be in touch if you are impacted.
          </p>
        </div>
      )}

      {which_failed.includes("retirement_age") && (
        <div className={classes.textbox}>
          <p>
            Retirement age needs to be a whole number not higher than your State
            Pension Age of {props.all_data["SPA"]} and not lower than your age
            at your next birthday (or your current age if today is your
            birthday) and not lower than 55
          </p>
        </div>
      )}
      <div
        className={`${classes.btn__container} ${
          (props.currentIndex >= maxIndex) &
          !props.forbidnext &
          (props.dict["receipt"] === "No") &
          !which_failed.includes("retirement_age")
            ? ""
            : classes.inactive
        }`}
      >
        <PageButton
          text="Calculate"
          link="/results"
          id="YourDetailsPage_Results_Button"
          onClick={handleSubmit}
        />
      </div>
    </form>
  );
};

export default InputForm;
