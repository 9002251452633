import React, { Fragment, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/layout/Header";
import TermsModal from "../components/layout/TermsModal";
import SurveyModal from "../components/layout/SurveyModal";

import VideoPopup from "../components/general/VideoPopup";
import TermsVideo from "../assets/videos/acknowledge_cropped.mp4";

function Layout(props) {

  const previously_paused_terms = useRef("No");

  const location = useLocation();

  const RESULTS_PATHNAME = "/results";

  const [showModal, setShowModal] = useState(true);
  const [showSurvey, setShowSurvey] = useState(false);
  const [hasSeenResults, setHasSeenReults] = useState(false);
  const [showTermsVideo, setShowTermsVideo] = useState(true)

  const closeModalHandler = () => {
    setShowModal(false);
    setShowTermsVideo(false);
  };


  const closeSurveyHandler = () => {
    setShowSurvey(false);
  };

  useEffect(() => {
    if (location.pathname === RESULTS_PATHNAME) {
      setHasSeenReults(true);
    }
  }, [location.pathname]);

  return (
    <Fragment>
      <Header />
      <main>{props.children}</main>
      {showModal && <TermsModal onClick={closeModalHandler} />}
      {showSurvey && hasSeenResults && (
        <SurveyModal onClick={closeSurveyHandler} />
      )}
      {showTermsVideo && (
        <VideoPopup
          videoTitle={"T&C Message Guide"}
          video={TermsVideo}
          previously_paused={previously_paused_terms}
        />
      )}
    </Fragment>
  );
}

export default Layout;
