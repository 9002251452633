import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import Layout from "./pages/0-Layout";
import HomePage from "./pages/1-Home";
import YourDetailsPage from "./pages/2-YourDetails";
import ResultsPage from "./pages/3-Results";
import TACPage from "./pages/4-TAC";
import params from './global_parameters' 
import MyContext from './context'


function App() {
  Object.freeze(params) /* make it impossible to modify parameters' */
  const [user_data, set_user_data] = useState([]);
  const currentURL = window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL;
  const [dict, setdict] = useState({});

  const [skips, setskips] = useState(0);

  const [currentIndex, setcurrentIndex] = useState(1);

  const [all_data, set_all_data] = useState(dict);

  const [ptinputs2, setInpts2] = useState([]);
  const [SPA, set_SPA] = useState(67);

  const [page, setpage] = useState(0);
  const [showwarning, setshowwarning] = useState(false); 

  const [forbidnext, setforbidnext] = useState(false);

  const [inputs_valid, set_inputs_valid] = useState(false)
  
  console.log('API is pointing to: ' + currentURL)

  useEffect(() => {
    const other_data = dict;
    set_all_data({ ...other_data, ["partTime"]: ptinputs2, ['SPA']: SPA });
  }, [ptinputs2, dict, SPA]);
 
  return (
    <MyContext.Provider value = {inputs_valid} >
    <Layout >
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route
          path="/yourdetails"
          element={
            <YourDetailsPage
              set_user_data={set_user_data}
              user_data={user_data}
              currentURL={currentURL}
              dict={dict}
              setdict={setdict}
              all_data={all_data}
              set_all_data={set_all_data}
              currentIndex={currentIndex}
              setcurrentIndex={setcurrentIndex}
              ptinputs2={ptinputs2}
              setInpts2={setInpts2}
              skips={skips}
              setskips={setskips}
              page={page}
              setpage={setpage}
              showwarning={showwarning}
              setshowwarning={setshowwarning}
              forbidnext={forbidnext}
              setforbidnext={setforbidnext}
              set_SPA = {set_SPA}
              set_inputs_valid = {set_inputs_valid}
            />
          }
        />
        <Route
          path="/results"
          element={<ResultsPage user_data={user_data} all_data = {all_data} set_user_data = {set_user_data} />}
        />

        <Route path="/T&Cs" element={<TACPage />} />
      </Routes>
    </Layout>
    </MyContext.Provider>
  );
}

export default App;


