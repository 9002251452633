import React, { useState, useEffect } from "react";
import { currenyFormatter } from "../../../utilities/Formatters";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import params from "../../../global_parameters";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle
);

function BarChart(props) {
  console.log('pension chart re-rendering')
  console.log('pre-remedy data for pension chart: ', [props.legacyPreRemedy, props.reformedPreRemedy])
  const post2022BarColor = "rgba(0, 99, 91, 0.6)";
  const remedyBarColor = "rgba(237, 139, 0, 1)";
  const preRemedyBarColor = "rgba(0, 99, 91, 0.85)";
  const titleFontSize = 20;
  const normalFontSize = 15;
  const fontColor = "rgb(0,0,0)";

  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    {
      props.showPreRemedy
        ? setChartData({
            labels: [
              ["Opt for " + props.scheme + " during", "remedy period"],
              ["Opt for alpha during", "remedy period"],
            ],
            datasets: [
              {
                label: "Pre-" + params.remedy_start,
                data: [props.legacyPreRemedy, props.reformedPreRemedy],
                borderColor: preRemedyBarColor,
                backgroundColor: preRemedyBarColor,
              },
              {
                label: "Remedy (" + params.remedy_start + "-22)",
                data: [props.legacyRemedy, props.reformedRemedy],
                borderColor: remedyBarColor,
                backgroundColor: remedyBarColor,
              },
              {
                label: "Post-2022",
                data: [props.legacyPost2022, props.reformedPost2022],
                borderColor: post2022BarColor,
                backgroundColor: post2022BarColor,
              },
            ],
          })
        : setChartData({
            labels: [
              ["Opt for " + props.scheme + " during", "remedy period"],
              ["Opt for alpha during", "remedy period"],
            ],
            datasets: [
              {
                label: "Remedy (" + params.remedy_start + "-22)",
                data: [props.legacyRemedy, props.reformedRemedy],
                borderColor: remedyBarColor,
                backgroundColor: remedyBarColor,
              },
              {
                label: "Post-2022 Pension",
                data: [props.legacyPost2022, props.reformedPost2022],
                borderColor: post2022BarColor,
                backgroundColor: post2022BarColor,
              },
            ],
          });
    }
    setChartOptions({
      Chart: { defaults: { color: [fontColor] } },
      indexAxis: "x",
      responsive: true,
      maintainAspectRatio: false,
      animation: true,
      // hover: null,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            font: {
              size: normalFontSize,
            },
            color: [fontColor],
          },
          onClick: false,
        },
        title: {
          display: true,
          text: "Estimated Gross Annual Pension at age " + props.retirementAge,
          font: {
            weight: "bold",
            size: titleFontSize,
          },
          padding: {
            bottom: 1,
          },
          color: [fontColor],
        },
        subtitle: {
          display: true,
          text: "Custom Chart Subtitle",
          text: "(Values in " + props.adjustInflationState + " terms)",
          font: {
            // weight: "bold",
            size: normalFontSize,
          },
          padding: {
            bottom: 20,
          },
          color: [fontColor],
        },
        interation: {
          mode: "y",
        },
        tooltip: {
          backgroundColor: "white",
          borderColor: "black",
          borderWidth: 1,
          titleColor: fontColor,
          titleFont: {
            size: normalFontSize,
          },
          bodyColor: fontColor,
          bodyFont: {
            size: normalFontSize,
          },
          yAlign: "center",
          callbacks: {
            title: function (context) {
              return context[0].label.replaceAll(",", " ");
            },
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              if (context.parsed.x !== null) {
                label += currenyFormatter(context.parsed.y);
              }
              return label;
            },
          },
        },
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value, context) => {
            const datasetArray = [];
            context.chart.data.datasets.forEach((dataset) => {
              if (dataset.data[context.dataIndex] !== undefined) {
                datasetArray.push(dataset.data[context.dataIndex]);
              }
            });
            function totalSum(total, datapoint) {
              return total + datapoint;
            }
            let sum = datasetArray.reduce(totalSum, 0);
            if (context.datasetIndex === datasetArray.length - 1) {
              return "Total Pension: " + currenyFormatter(sum);
            } else {
              return "";
            }
          },
          font: {
            size: normalFontSize,
          },
          color: [fontColor],
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: "Value in " + props.adjustInflationState + " terms",
            font: {
              size: normalFontSize * 1.2,
            },
            color: fontColor,
          },
          stacked: true,
          grid: { display: false },
          ticks: {
            callback: function (value) {
              return currenyFormatter(value);
            },
            font: {
              size: normalFontSize,
            },
            color: [fontColor],
          },
          // grace: 1000,
        },
        x: {
          stacked: true,
          grid: { display: false },
          ticks: {
            font: {
              size: normalFontSize,
            },
            color: [fontColor],
          },
        },
      },
    });
  }, [
    props.equaliseLumpSumState,
    props.adjustInflationState,
    props.retirementAgeState,
    props.showPreRemedy,
    props.force_chart_repaint
  ]);

  return (
    <Bar options={chartOptions} data={chartData} plugins={[ChartDataLabels]} />
  );
}

export default BarChart;
