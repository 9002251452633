import React, { Fragment, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import classes from "./TermsModal.module.css";

const Backdrop = () => {
  return <div className={classes.background} />;
};

const ModalOverlay = (props) => {
  const [tcsAgreed, setTcsAgreed] = useState(false);

  const tcsCheckHandler = () => {
    setTcsAgreed(!tcsAgreed);
  };

  return (
    <Fragment>
      <div className={classes.modal}>
        <h2>Remedy Benefits Illustrator - Please Read!</h2>
        <p>
          Welcome to the Remedy Benefits Illustrator. Please read these terms of
          service before using the illustrator. It is for guidance and
          illustration purposes only.{" "}
          <b>The figures shown are not a final retirement benefit offer </b>
          and are designed to illustrate your basic service benefits only. The
          illustrator is not intended to provide you with financial advice. If
          you require financial advice you should contact an independent
          financial adviser.
        </p>
        <p>The assumptions we have used in this illustrator are:</p>
        <table>
          <tbody>
            <tr>
              <td>Inflation</td>
              <td>2% per year</td>
            </tr>
            <tr>
              <td>General pay increases</td>
              <td>2% per year</td>
            </tr>
          </tbody>
        </table>
        <p>
          <b>
            We do not accept any liability for actions taken by members with
            regards to these illustrations.
          </b>
        </p>
        <label className={classes.checkbox_label}>
          <input
            className={classes.checkbox}
            checked={tcsAgreed}
            type="checkbox"
            onChange={tcsCheckHandler}
          />
          {"  "}I acknowledge that I have read, agree and understand the terms
          of service
        </label>
        <button
          className={`${classes.btn_main} ${
            tcsAgreed ? classes.btn_agreed : classes.btn_reject
          }`}
          onClick={tcsAgreed ? props.onClick : undefined}
        >
          Continue to the Remedy Benefits Illustrator
        </button>
      </div>
    </Fragment>
  );
};

const TermsModal = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay onClick={props.onClick} />,
        document.getElementById("modal-root")
      )}
      {ReactDOM.createPortal(
        <Backdrop />,
        document.getElementById("backdrop-root")
      )}
    </Fragment>
  );
};

export default TermsModal;
