import moment from "moment";
import { Fragment } from "react";
import params from "../../global_parameters";

export const MemberQuestions = [
  {
    id: "receipt",
    index: 1,
    title: "",
    text:
      "Are you currently in receipt of all or part of your " +
      window.scheme_name +
      " pension?",
    type: "select",
    options: ["Yes", "No"],
    ifbad: "not used",
  },
  {
    id: "if_left",
    index: 2,
    title: "",
    text: "Have you already left the scheme?",
    type: "select",
    options: ["Yes", "No"],
    ifbad: "arbitrary",
  },
  {
    id: "dateLeftScheme",
    index: 3,
    title: "",
    text: "When did you leave the pension scheme?",
    type: "date",
    maxval: (dict) => {
      return new Date();
    },
    minval: (dict) => {
      return new Date("2012-03-31");
    }, //otherwise McCloud remedy would be irrelevant
    options: ["arbitrary"],
    ifbad: "arbitrary",
    selected: new Date("2015-04-01"),
  },
  {
    id: "scheme",
    index: 4,
    title: "",
    text:
      "Which " +
      window.scheme_name +
      " scheme were you a member of prior to 1st April " +
      params.remedy_start +
      "?",
    type: "select",
    options: ["Classic", "Classic Plus", "Premium", "Nuvos"],
    ifbad: "arbitrary",
  },
  {
    id: "dateOfBirth",
    index: 5,
    title: "",
    text: "What is your date of birth?",
    type: "date",
    maxval: (dict) => {
      return new Date("1991-07-30");
    }, //15 years before end of Premium is too implausible. 16yo apprentice just about possible.
    minval: (dict) => {
      return new Date(moment().subtract(66, "years").format("YYYY-MM-DD"));
    }, //can't be more than 66 years before today
    options: ["arbitrary"],
    ifbad: "arbitrary",
    selected: new Date("1970-01-01"),
  },
  {
    id: "dateJoinedScheme",
    index: 6,
    title: "",
    text: "What date did you join the " + window.scheme_name + " scheme?",
    type: "date",
    minval: (dict) => {
      if (dict["dateOfBirth"] === undefined) {
        var ret = "";
      } else {
        ret = new Date(
          moment(new Date(dict["dateOfBirth"]))
            .add(16, "years")
            .format("YYYY-MM-DD")
        );
      }
      return ret;
    },
    maxval: (dict) => {
      return new Date("2012-03-31"); 
    },
    options: ["arbitrary"],
    ifbad: "arbitrary",
    selected: new Date("2000-01-01"),
    validation: (dict, maxval, minval) => {return(maxval >= new Date(dict['dateJoinedScheme']))}
  },
  {
    id: "salary1",
    index: 7,
    title: "",
    text: "What is your most recent gross full time annual pay?",
    type: "number",
    options: ["arbitrary"],
    ifbad: "arbitrary",
    validation: (dict, arb1, arb2) => {
      return dict["salary1"] !== "";
    },
    maxval: (dict) => {
      return "999999999999";
    },
    minval: (dict) => {
      return "0";
    },
  },
  {
    id: "salary2",
    index: 8,
    title: "",
    text: (
      <Fragment>
        <p>
          What was your gross full time annual pay on 1 April{" "}
          {params.remedy_start}?
        </p>
        <p>(If unknown, please enter 0)</p>
      </Fragment>
    ),
    type: "number",
    options: ["arbitrary"],
    ifbad: "arbitrary",
    validation: (dict, arb1, arb2) => {
      return dict["salary2"] !== "";
    },
    maxval: (dict) => {
      return "999999999999";
    },
    minval: (dict) => {
      return "0";
    },
  },
  {
    id: "retirement_age",
    index: 9,
    title: "Part time periods",
    text: "At what age do you plan to retire?",
    type: "number",
    minval: (dict) => {
      var birthday = moment(new Date(dict["dateOfBirth"]));
      var todays_date = moment();
      if (dict["dateOfBirth"] === undefined) {
        var ret = "";
      } else {
        var ret = Math.max(
          todays_date.diff(birthday, "years") +
            //if today is the user's birthday, then min retirement age is their age today.
            //But if today is not their birthday, min retirement age is their age today (rounded down to nearest integer) plus 1
            //So the below checks if today is their birthday, and if not, it adds 1 (NB `true` is same as `1`) to min ret age
            !(
              todays_date.date() === birthday.date() &&
              todays_date.month() === birthday.month()
            ),
          55
        );
      }
      return ret;
    },
    maxval: (dict) => {
      console.log(dict["SPA"]);
      return dict["SPA"];
    },
    options: ["arbitrary"],
    ifbad: "arbitrary",
    validation: (dict, max_allowed, min_allowed) => {
      return (
        ((dict["retirement_age"] <= max_allowed) &
          (dict["retirement_age"] >= min_allowed)) |
        (dict["retirement_age"] === undefined)
      );
    },
  },
  {
    id: "if_pt",
    index: 10,
    title: "",
    text: "Have you ever been part-time?",
    type: "select",
    options: ["Yes", "No"],
    ifbad: "arbitrary",
  },
  /*{
    id: "how_pt2",
    index: 10,
    title: "remove",
    text: "Tell us about your part time working history",
    type: "how_pt",
    options: ["Fix", "This", "Later"],
    ifbad: "arbitrary"
  },
  */
  {
    id: "how_pt",
    index: 11,
    title: "Part time periods",
    text: <h3>Tell us about your part-time working history</h3>,
    type: "how_pt",
    options: ["arbitrary"],
    ifbad: "arbitrary",
  },
  {
    id: "q1000",
    index: 1000,
    title: "Question 1000",
    text: "Dummy",
    type: "dummy",
    options: ["arbitrary"],
    ifbad: "arbitrary",
  },
];

export const NonMemberQuestions = [
  {
    id: "q1",
    index: 1,
    title: "Question 1",
    text: "What is your date of birth?",
    type: "date",
  },
  {
    id: "q2",
    index: 2,
    title: "Question 2",
    text:
      "What " +
      window.scheme_name +
      " Scheme were you a member of prior to 1st April 2015?",
    type: "select",
  },
  {
    id: "q3",
    index: 3,
    title: "Question 3",
    text: "What date did you join the " + window.scheme_name + " scheme?",
    type: "date",
  },
  {
    id: "q4",
    index: 4,
    title: "Question 4",
    text: "When did you leave the pension scheme?",
    type: "date",
  },
  {
    id: "q5",
    index: 5,
    title: "Question 5",
    text: "What was your gross pay as at the date you left the scheme?",
    type: "number",
  },
  {
    id: "q6",
    index: 6,
    title: "Question 6",
    text: "What was your salary as at 1st April 2015? (If this is unknown then please leave this blank)",
    type: "number",
  },
];
