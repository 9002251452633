import React, { useState, useEffect } from "react";

import classes from "./InputForm.module.css";

import PTInput2 from "./PTInputfunction";

export default function PTInputs2({
  ptinputs2,
  setInputs2,
  djs,
  dls,
  forbidnext,
  setforbidnext,
  page,
  setpage,
  showwarning,
  setshowwarning,
}) {
  function handleAdd() {
    const tempInputs = [...ptinputs2];
    tempInputs.push({ From: "", To: "", Proportion: "" });
    setInputs2(tempInputs);
  }
  function handleChange({ value, index, name }) {
    const tempInputs = [...ptinputs2];
    tempInputs[index][name] = value;
    setInputs2(tempInputs);

    if (
      (index > 0 &&
        tempInputs[index]["From"] !== "" &&
        tempInputs[index]["To"] !== "" &&
        tempInputs[index]["To"] !== null &&
        tempInputs[index]["From"] !== null &&
        tempInputs[index - 1]["To"] >= tempInputs[index]["From"]) |
      (tempInputs[index]["To"] !== "" &&
        tempInputs[index]["To"] !== null &&
        tempInputs[index]["From"] !== "" &&
        tempInputs[index]["From"] !== null &&
        tempInputs[index]["To"] <= tempInputs[index]["From"])
    ) {
      setforbidnext(true);
    } else {
      setforbidnext(false);
    }

    //  if (index === Object.keys(tempInputs).length - 1) {
    //    handleAdd();
    //  }

    setshowwarning(false);
  }

  function handleNext() {
    const tempInputs = [...ptinputs2];

    if (
      tempInputs[page] === undefined ||
      tempInputs[page]["From"] === "" ||
      tempInputs[page]["To"] === "" ||
      tempInputs[page]["Proportion"] === ""
    ) {
      setshowwarning(true);
    } else {
      setshowwarning(false);
      if (!forbidnext) {
        setpage(page + 1);
        handleAdd();
      }
      /*
      if (
        tempInputs === undefined ||
        page - 1 === Object.keys(tempInputs).length - 1
      ) {
        handleAdd();
      }
      */
    }
  }
  const [remove_entries, set_remove_entries] = useState(false);

  function handleBack() {
    setpage(Math.max(page - 1, 0));

    set_remove_entries(!remove_entries);

    if (ptinputs2.length > 1) {
      setInputs2(
        ptinputs2.filter((item, ind) => {
          return ind <= Math.max(ptinputs2.length - 2, 0);
        })
      );
      setforbidnext(false);
      setshowwarning(false);
    }
  }

  useEffect(() => {
    if (ptinputs2.length === 0) {
      handleAdd();
    }
  }, []);

  return (
    <div className={classes.main__input__container}>
      <section>
        {ptinputs2.map((ptinput2, index, arr) => {
          return (
            <PTInput2
              key={index}
              handleChange={handleChange}
              index={index}
              input={ptinput2}
              arr={arr}
              page={page}
              showwarning={showwarning}
              forbidnext={forbidnext}
              djs={djs}
              dls={dls}
            />
          );
        })}
      </section>
      <div className={classes.button__container}>
        <button className={classes.button} onClick={handleBack}>
          Back
        </button>
        <button onClick={handleNext} className={classes.button}>
          Next
        </button>
      </div>
    </div>
  );
}
