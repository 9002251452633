//import ChatButton from "../general/buttons/ChatButton";
import classes from "./InputForm.module.css";
import Optss from "../general/buttons/opts.js";
import React, { Fragment } from "react";

import Typography from "@mui/material/Typography";
import PTInputs2 from "./PTInputs2";

import Tooltip from "@mui/material/Tooltip";

import InfoIcon from "@mui/icons-material/InfoOutlined";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//import { PropaneSharp } from "@mui/icons-material"; 

//import Select from 'react-select';

const Question = (props) => {
  if(props.id === 'dateJoinedScheme') {
    console.log('st', !props.still_typing)
  }
  const dls =
    props.dict["dateLeftScheme"] === undefined
      ? "2099-12-31"
      : props.dict["dateLeftScheme"];

  const available_options = props.options.map((x) => {
    return <Optss key={x} value={x} selected={props.dict[props.id] === x} />;
  });

  return (
    <Fragment>
      <section
        className={`${
          props.thisIndex <= props.currentIndex &&
          !props.already_receipt &&
          (!props.not_pt || props.not_pt === undefined) &&
          (!props.not_left || props.not_left === undefined) &&
          //(!props.member_nuvos) &&
          !props.validation_issue
            ? classes.active
            : classes.inactive
        }`}
      >
        <div className={classes.textbox} id={props.id}>
          <div className={classes.questioninfo}>
            <p className={classes.tooltip}>{props.text}</p>
            {props.SideText !== "blank" && (
              <Tooltip
                className={classes.tooltip}
                title={
                  <Typography className={classes.tooltip}>
                    {props.SideText}
                  </Typography>
                }
              >
                <InfoIcon className={`${classes.tooltip} ${classes.icon}`} />
              </Tooltip>
            )}
          </div>

          {(() => {
            switch (`${props.type}`) {
              case "date":
                return (
                  <>
                    <DatePicker
                      selected={
                        props.dict[props.id] === undefined
                          ? null
                          : props.dict[props.id]
                      }
                      openToDate={
                        props.dict[props.id] === undefined
                          ? props.selected
                          : props.dict[props.id]
                      }
                      className={classes.input}
                      minDate={props.minval}
                      maxDate={props.id === "dateJoinedScheme" ? new Date() : props.maxval}
                      onChange={props.DateInputGetter}
                      showYearDropdown
                      yearDropdownItemNumber={40}
                      scrollableYearDropdown={true}
                      dateFormat="dd/MM/yyyy"
                    >
                      {" "}
                    </DatePicker>

                    {(props.id === "dateJoinedScheme") &&
                    (props.dict["scheme"] === "Premium") &&
                    (new Date(props.dict[props.id]) <
                      new Date("2002-10-01")) &&
                      (!props.still_typing) ? (
                      <p className = {classes.err_message}>
                        {" "}
                        If you are a Premium member with a date of joining the
                        scheme before October 2002, we will assume that you
                        opted to convert your pre-October 2002 service into
                        premium service. Your pre-October 2002 service will be
                        multiplied by 0.92 when calculating final salary
                        benefits in line with regulations. In some cases, the
                        factor used to convert your pre-October 2002 service
                        into the Premium scheme may differ slightly from 0.92
                        but this has not been allowed for.
                      </p>
                    ) : ((
					          (props.id === "dateJoinedScheme") &&
                    (new Date(props.dict[props.id]) > new Date('2012-03-31')) &&
                    props.dict[props.id] &&
                    (!props.still_typing)
                    
                    )
					? 
					<p className = {classes.err_message}> Your joining date cannot be after 31 March 2012 </p> 
					:
					null
					
					)}
                  </>
                );
              case "select":
                return (
                  <select
                    className={classes.input}
                    onChange={props.InputGetter}
                    value={
                      props.dict[props.id] === undefined
                        ? "default_value"
                        : props.dict[props.id]
                    }
                  >
                    <option
                      style={{ display: "none" }}
                      value={"default_value"}
                    ></option>
                    {available_options})
                  </select>
                );
              case "number":
                return (
                  <input
                    className={classes.input}
                    type="number"
                    min={props.minval === undefined ? "0" : props.minval}
                    max={props.maxval === undefined ? null : props.maxval}
                    step="any"
                    required
                    onChange={props.InputGetter}
                    value={
                      (props.dict[props.id] === undefined) |
                      (props.dict[props.id] === "")
                        ? ""
                        : Math.floor(props.dict[props.id])
                    }
                  />
                );
              case "how_pt":
                return (
                  <Fragment>
                    <div className={classes.parttime__text}>
                      <p>
                        If you have never served a period of part-time service
                        then leave this blank.
                      </p>
                      <p>
                        Please enter periods of part time service in
                        chronological order.
                      </p>
                      <p>
                        If you have had more than one period of part-time
                        service, enter details for each period. The value in the
                        'percentage' field should have a number between 0 and
                        100 to reflect the percentage of full time equivalent
                        hours that you worked during the relevant period. For
                        example, if you worked 28 hours per week when the full
                        time equivalent was 35 hours per week, your part time
                        proportion would be 80%, in which case you should write
                        '80' in the percentage field.
                      </p>
                      <p>
                        Click 'Next' to a separate period of part time service,
                        or click 'Calculate' if you have finished adding part
                        time service.
                      </p>
                    </div>
                    <PTInputs2
                      ptinputs2={props.ptinputs2}
                      setInputs2={props.setInpts2}
                      djs={props.dict["dateJoinedScheme"]}
                      dls={dls}
                      setforbidnext={props.setforbidnext}
                      forbidnext={props.forbidnext}
                      page={props.page}
                      setpage={props.setpage}
                      showwarning={props.showwarning}
                      setshowwarning={props.setshowwarning}
                    />
                  </Fragment>
                );
              case "dummy": //this is a dummy question not actually used, it just exists to ensure we only show calculate button
                //when last question is visible
                return (
                  <input
                    className={classes.input}
                    type="number"
                    required //you would think we shouldn't have 'required here... but actually for some reason we
                    //need this to prevent the whole page reloading when you click 'Next' in the part time inputs...
                  />
                );
            }
          })()}
        </div>
        {/* <div className={classes.TBC}></div> */}
      </section>
    </Fragment>
  );
};

export default Question;
