import React, {useState, useEffect} from "react";

import QuestionSelector from "../components/home/QuestionSelector";
import TextBlock from "../components/general/text/TextBlock";
import Footer from "../components/home/Footer";

import MainTextModal from "../components/general/VideoModalMainText";
import HomeVideo from "../assets/videos/homepage_cropped.mp4";

import { useScrollPosition } from "../utilities/UseScrollPosition"

import FrontPageQuestionSelectorText from "../assets/data/FrontPageQuestionSelectorText";
import { IntroText, SmallPrintText } from "../assets/data/TextBlocks";

function HomePage() {

  const scrollPosition = useScrollPosition();
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const [showModal, setShowModal] = useState(true);
  useEffect(() => {
    if (scrollPosition > 90) {
      setIsScrolledDown(true);
    } else {
      setIsScrolledDown(false);
    }
  }, [scrollPosition]);

  return (
    <div id="Home_Page">
      {showModal && <div style={{ height: "9.75rem" }}></div>}
      <MainTextModal
        videoTitle={"Home Page Guide"}
        video={HomeVideo}
        at_top={!isScrolledDown}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <TextBlock blocks={IntroText} />
      <QuestionSelector items={FrontPageQuestionSelectorText} />
      <TextBlock blocks={SmallPrintText} />
      <Footer />
    </div>
  );
}

export default HomePage;
