import React from "react";

const Optss = (props) => {
    return(
        <option 
        key = {props.value} 
        value = {props.value} 
        //selected = {props.selected}
        > {props.value}  
        </option>
    );
};

export default Optss;