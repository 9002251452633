import React, { useState, useEffect } from "react";
import { currenyFormatter } from "../../../utilities/Formatters";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle
);

function BarChart(props) {
  const lumpSumBarColor = "rgba(138, 27, 97, 0.75)";
  const titleFontSize = 20;
  const normalFontSize = 15;
  const fontColor = "rgb(0,0,0)";

  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    setChartData({
      labels: [
        ["Opt for " + props.scheme + " during", "remedy period"],
        ["Opt for alpha during", "remedy period"],
      ],
      datasets: [
        {
          label: "Lump Sum",
          data: [props.legacyLumpSum, props.reformedLumpSum],
          borderColor: lumpSumBarColor,
          backgroundColor: lumpSumBarColor,
        },
      ],
    });
    setChartOptions({
      Chart: {
        defaults: { color: fontColor },
      },
      indexAxis: "x",
      responsive: true,
      maintainAspectRatio: false,
      animation: true,
      // hover: null,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            font: {
              size: normalFontSize,
            },
            color: [fontColor],
          },
          onClick: false,
        },
        title: {
          display: true,
          text: "Estimated Lump Sum at age " + props.retirementAge,
          font: {
            weight: "bold",
            size: titleFontSize,
          },
          padding: {
            bottom: 1,
          },
          color: [fontColor],
        },
        subtitle: {
          display: true,
          text: "Custom Chart Subtitle",
          text: "(Values in " + props.adjustInflationState + " terms)",
          font: {
            // weight: "bold",
            size: normalFontSize,
          },
          padding: {
            bottom: 20,
          },
          color: [fontColor],
        },
        tooltip: {
          // enabled: false,
          interaction: {
            intersect: false,
          },
          backgroundColor: "white",
          borderColor: "black",
          borderWidth: 1,
          titleColor: fontColor,
          titleFont: {
            size: normalFontSize,
          },
          bodyColor: fontColor,
          bodyFont: {
            size: normalFontSize,
          },
          yAlign: "center",
          callbacks: {
            title: function (context) {
              return context[0].label.replaceAll(",", " ");
            },
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              if (context.parsed.x !== null) {
                label += currenyFormatter(context.parsed.y);
              }
              return label;
            },
          },
        },
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value, context) => {
            const datasetArray = [];
            context.chart.data.datasets.forEach((dataset) => {
              if (dataset.data[context.dataIndex] !== undefined) {
                datasetArray.push(dataset.data[context.dataIndex]);
              }
            });
            function totalSum(total, datapoint) {
              return total + datapoint;
            }
            let sum = datasetArray.reduce(totalSum, 0);
            if (context.datasetIndex === datasetArray.length - 1) {
              return "Lump Sum: " + currenyFormatter(sum);
            } else {
              return "";
            }
          },
          font: {
            size: normalFontSize,
          },
          color: [fontColor],
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: "Values in " + props.adjustInflationState + " terms",
            font: {
              size: normalFontSize * 1.2,
            },
            color: [fontColor],
          },
          stacked: true,
          grid: { display: false },
          ticks: {
            callback: function (value) {
              return currenyFormatter(value);
            },
            font: {
              size: normalFontSize,
            },
            color: fontColor,
          },
          // grace: 1000,
        },
        x: {
          stacked: true,
          grid: { display: false },
          ticks: {
            font: {
              size: normalFontSize,
            },
            color: [fontColor],
          },
        },
      },
    });
  }, [
    props.equaliseLumpSumState,
    props.adjustInflationState,
    props.retirementAgeState,
    props.force_chart_repaint
  ]);

  return (
    <Bar options={chartOptions} data={chartData} plugins={[ChartDataLabels]} />
  );
}

export default BarChart;
