import React, { Fragment, useState } from "react";
import InfoModal from "../../general/InfoModal";
import classes from "./ControlSwitches.module.css";

const SalarySlider = (props) => {
  const [showModal, setShowModal] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const sliderHandler = (event) => {
    props.setMouseDown(true);
    props.setSalaryIncrease(event.target.value);
  };

const params = {
remedy_start : 2015,
app_name_long : "Remedy Benefits Illustrator",
app_name_short : "illustrator"}


  const infoMessage = (
    <Fragment>
      <h2>Salary Increases</h2>
      <p> 
      The default salary increases are set to 2% pa, i.e. in line with assumed CPI of 2% pa. A salary increase figure higher than 2% represents year-on-year salary increases in real terms, whereas a figure below 2% represents year-on-year salary decreases in real terms.
      </p>
      <p> 
      The value set on the slider can affect the modeller's assumptions about both your past and future salary. 
      If you did not provide a salary for 1 April {params.remedy_start} on the 'Your Details' page, setting the
       salary increase to a higher value will cause the model to estimate a lower value for 
       your past salary, which in turn will reduce the Alpha { props.legacySchemeName === 'Nuvos' ? 'and Nuvos ' : ''} pension 
       that 
       it will estimate you accrued over the remedy period.”
      </p>
    </Fragment>
  );

  return (
    <div className={classes.main_container}>
      {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
      )}
      <header className={classes.header}>
        <h3>Salary Increases</h3>
        <button
          id="LumpSumSwitchHelp"
          className={classes.help_button}
          onClick={modalHandler}
        >
          Help
        </button>
      </header>
      <p>
        This slider can set the annual salary increases you will receive until leaving the scheme. Your pension is directly
        linked to salary increases.
      </p>
      <div className={classes.radio_container}>
        <input
          id="lumpSum"
          type="range"
          min="0"
          max="5"
          step="0.1"
          value={props.salaryIncrease}
          onChange={sliderHandler}
          className="slider"
          onMouseUp={() => {
            props.setMouseDown(false);
          }}
          onKeyUp={() => {
            props.setMouseDown(false);
          }}
        />
        <p>{props.salaryIncrease}%</p>
      </div>
    </div>
  );
};

export default SalarySlider;
