import React, { Fragment, useState } from "react";
import InfoModal from "../../general/InfoModal";

import classes from "./ControlSwitches.module.css";

const LumpSumSwitch = (props) => {
  const [showModal, setShowModal] = useState(false);

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const infoMessage = (
    <Fragment>
      <h2>Equalise Lump Sums</h2>
      <p>
        When the switch is turned off, it is assumed no commutation (exchange of
        pension for a one-off lump sum) is taken in either the legacy or alpha
        scheme beyond any automatic lump sum from Classic. When the switch is
        turned on, it is assumed that commutation taken in the alpha scheme
        scenario is such that total lump sums are equal in both scenarios
        including any automatic lump sum from Classic.
      </p>
    </Fragment>
  );

  return (
    <div className={classes.main_container}>
      {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
      )}
      <header className={classes.header}>
        <h3>Equalise Lump Sums</h3>
        <button
          id="LumpSumSwitchHelp"
          className={classes.help_button}
          onClick={modalHandler}
        >
          Help
        </button>
      </header>
      <p>
        You can use these buttons to set additional commutation to zero or set
        lump sums to be equal in both scenarios.
      </p>
      {props.showEqualiseWarning && (
        <p className={classes.warning}>
          <b>Please note:</b>In this scenario, your alpha pension can't match
          the Classic scheme's lump sum due to the 35.7% commutation cap in
          alpha. The commutation rate for alpha has therefore been set to this
          35.7% maximum.
        </p>
      )}
      <div className={classes.radio_container}>
        <input
          type="radio"
          id="radioUnequal"
          name="radioEqualiseLumpSum"
          value="unequal"
          onChange={() => props.setEqualiseLumpSum("unequal")}
          defaultChecked
        />
        <label htmlFor="radioUnequal">Unequal</label>
        <input
          type="radio"
          id="radioEqual"
          name="radioEqualiseLumpSum"
          value="equal"
          onChange={() => props.setEqualiseLumpSum("equal")}
        />
        <label htmlFor="radioEqual">Equal</label>
      </div>
    </div>
  );
};

export default LumpSumSwitch;
