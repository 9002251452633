import React, { useState, useEffect } from "react";

import { createTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import moment from "moment";
import classes from "./InputForm.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function PTTo({
  index,
  input,
  handleChange,
  arr,
  page,
  showwarning,
  forbidnext,
  djs,
  dls,
  ...props
}) {
  //console.log(arr)
  //console.log(page)

  const colorTheme = "#af292e";
  const themeGAD = createTheme({
    palette: {
      primary: {
        light: "#af292e",
        main: "#af292e",
        dark: "#af292e",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });

  return (
    <div>
      <React.Fragment key={index + 5000}>
        <LocalizationProvider key={index + 4000} dateAdapter={DateAdapter}>
          <div {...props} className={classes.parttime__container}>
            {
              <div className={classes.parttime__index}>
                <p>{index + 1}.</p>
              </div>
            }
            {
              //index === page &&
              <div className={classes.parttime__datepicker}>
                <p className={classes.label}> From </p>
                <DatePicker
                  key={index + 1000}
                  selected={
                    input["From"] === "" ? null : new Date(input["From"])
                  }
                  openToDate={
                    input["From"] === "" ? null : new Date(input["From"])
                  }
                  dateFormat="dd/MM/yyyy" //{index === page ? "dd/MM/yyyy" : undefined}
                  //type='date'
                  onChange={(e) =>
                    handleChange({
                      value: e,
                      index: index,
                      name: "From",
                    })
                  }
                  minDate={new Date(djs)} //{index === page ? new Date(djs) : undefined}
                  maxDate={new Date(dls)} //{index === page ? new Date(dls) : undefined}
                  renderInput={(params) => <TextField {...params} />}
                  //className={classes.input}
                  showYearDropdown
                  yearDropdownItemNumber={40}
                  scrollableYearDropdown={true}
                />
              </div>
            }
            {
              //index === page &&
              <div className={classes.parttime__datepicker}>
                <p className={classes.label}> To </p>
                <DatePicker
                  key={index + 2000}
                  label="To"
                  selected={input["To"] === "" ? null : new Date(input["To"])}
                  openToDate={input["To"] === "" ? null : new Date(input["To"])}
                  dateFormat={"dd/MM/yyyy"} //index === page ? "dd/MM/yyyy" : undefined}
                  onChange={(e) =>
                    handleChange({
                      value: e,
                      index: index,
                      name: "To",
                    })
                  }
                  minDate={new Date(djs)} //{index === page ? new Date(djs) : undefined}
                  maxDate={new Date(dls)} //{index === page ? new Date(dls) : undefined}
                  renderInput={(params) => <TextField {...params} />}
                  showYearDropdown
                  yearDropdownItemNumber={40}
                  scrollableYearDropdown={true}
                />
              </div>
            }

            {
              //index === page &&
              <div className={classes.parttime__datepicker}>
                <p className={classes.label}> Percentage (%) </p>
                <input
                  min="0"
                  max="100"
                  className="parttime__proportion"
                  key={index + 3000}
                  type="number"
                  // InputProps={{ inputProps: { min: 0, max: 100 } }} //index === page ? { inputProps: { min: 0, max: 100 } } : undefined}
                  defaultValue={input["Proportion"]}
                  variant="standard"
                  // style={{
                  //   marginLeft: "10px",
                  //   marginTop: "27px",
                  //   color: colorTheme,
                  //   width: 200,
                  // }}
                  onChange={(e) =>
                    handleChange({
                      value: e.target.value,
                      index: index,
                      name: "Proportion",
                    })
                  }
                />
              </div>
            }
            {
              //index === page && <p> Message here to say we are on part time input number {page + 1}</p>}
            }
          </div>
          <div className={classes.warnings}>
            {
              //index === page &&
              input["To"] !== "" &&
                input["To"] !== null &&
                input["From"] !== "" &&
                input["From"] !== null &&
                input["To"] <= input["From"] && (
                  <p>This 'From' date is not earlier than the 'To' date.</p>
                )
            }
            {
              //input["From"] !== undefined &&
              //input["To"] !== undefined &&
              index > 0 &&
                index === page &&
                input["From"] !== "" &&
                input["To"] !== "" &&
                input["To"] !== null &&
                input["From"] !== null &&
                //input["To"] !== '' &&
                // parseFloat(input["To"]) > parseFloat(input["From"]) &&
                arr[index - 1]["To"] >= input["From"] && (
                  <p>This 'From' date is earlier than previous 'To' date.</p>
                )
            }
            {showwarning && index === page && (
              <p>You must complete the fields before clicking next.</p>
            )}
            {forbidnext && index === page && (
              <p>You cannot proceed until invalid inputs are corrected.</p>
            )}
          </div>
        </LocalizationProvider>
      </React.Fragment>
    </div>
  );
}

export default PTTo;
