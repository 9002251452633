import React, { Fragment, useState, useEffect } from "react";

import InputForm from "../components/yourdetails/InputForm";

import MainTextModal from "../components/general/VideoModalMainText";
import DetailsVideo from "../assets/videos/member_input_cropped.mp4";

import { useScrollPosition } from "../utilities/UseScrollPosition";

function YourDetailsPage(props) {

  const scrollPosition = useScrollPosition();
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const [showModal, setShowModal] = useState(true);
  useEffect(() => {
    if (scrollPosition > 90) {
      setIsScrolledDown(true);
    } else {
      setIsScrolledDown(false);
    }
  }, [scrollPosition]);

  return (
    <div id="Your_Details_Page">
      {showModal && <div style={{ height: "9.75rem" }}></div>}
      <MainTextModal
        videoTitle={"Details Page Guide"}
        video={DetailsVideo}
        at_top={!isScrolledDown}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <h2>Tell us about your career</h2>
      <p>
        Please answer the following questions to allow us to provide you with an
        estimate of your pension benefits.
      </p>
      <InputForm
        addressAPI={props.addressAPI}
        set_user_data={props.set_user_data}
        user_data={props.user_data}
        currentURL={props.currentURL}
        dict={props.dict}
        setdict={props.setdict}
        all_data={props.all_data}
        set_all_data={props.set_all_data}
        currentIndex={props.currentIndex}
        setcurrentIndex={props.setcurrentIndex}
        ptinputs2={props.ptinputs2}
        setInpts2={props.setInpts2}
        skips={props.skips}
        setskips={props.setskips}
        page={props.page}
        setpage={props.setpage}
        showwarning={props.showwarning}
        setshowwarning={props.setshowwarning}
        forbidnext={props.forbidnext}
        setforbidnext={props.setforbidnext}
        set_SPA = {props.set_SPA}
        set_inputs_valid = {props.set_inputs_valid}
      />
    </div>
  );
}

export default YourDetailsPage;
